
body{
    font-family: Tahoma, Helvetica, Arial, sans-serif !important;
}
.header-container{
    background: linear-gradient(0deg, #D2DEF33b 0%, #fff 20%);
    border-bottom: 1px solid #D2DEF3;
    padding: 1px 0 10px;
}

.header-container.ar{
    padding-top: 3px;
   .logo{
    padding-bottom: 8px;
    margin-left: 172px;
   } 
   img{
    width: 464px;
    margin-left:0px;
   }
}

.logo{
    display: flex;
    justify-content: center;
    padding-bottom: 5px;

    img{
        width: 409px;
        margin-left:12px;
    }
}

.page-title{
    font-size: 26px !important;
  color: #4277b9;
  text-align: center;
  font-weight: 500;
  margin-left: -3px;
}

.page-title.ar{
    margin-left: -43px;
}
.container{
    width: 1000px;
    margin: auto;
}
.page-container{
    padding-top: 15px;
}

.pdf-view-container{
    background: url('../public/assets/esw-bg.jpg') no-repeat top center;
}

.pdf-view-container.ar{
    .accordian-control{
        flex-direction: row-reverse;
    }

        display: flex;
        flex-direction: column;
        align-items: end;
    
}

.accordian-control{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 7px 8px;
    color: #585858;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;

    &:not(.active) img{
        rotate: 180deg;
    }

    &.active{
        background: #FFE07B;
        padding: 5px 7px 3px;
        color: #000;
        font-weight: 500;
        border-bottom: 13px solid #fff;
        width: calc(100% - 30px);
    }
}

.pdf-viewer{
    width: 100%;
    max-width: 987px;
    height: 600px;
}

.nav-bar{
  position: relative;
}

.set-lang.ar{ left: 0; }
.set-lang{
    position: absolute;
    right: 0; 
    top: 12px;
   display: flex;
    align-items: center;

    .btn{
        position: relative;
        padding: 0 10px;
        font-weight: 400;
    }

    .btn.active,.btn:hover{
        color: #4277b9;
        cursor: pointer;
    }

    .btn.active::before{
            position: absolute;
            background: url('../public/assets/tableOpen.png') center no-repeat;
            content: '';
            width: 100%;
            height: 12px;
            top: -12px;
            left: 0;
    }

    .btn:hover::before{
            position: absolute;
            background: url('../public/assets/tableOpen.png') center no-repeat;
            content: '';
            width: 100%;
            height: 12px;
            top: -12px;
            left: 0;
        
    }

    .btn:first-child{
        border-right: 1px solid #D2DEF3;
    }
}

.arab{
    font-size: 16px;
}